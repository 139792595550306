import { base } from "@theme-ui/presets"

export default {
  ...base,
  sizes: {
    container: "800px",
  },
  breakpoints: ["800px"],
  radii: {
    none: "0px",
    small: "2px",
    large: "4px",
  },
  fonts: {
    ...base.fonts,
    body: `"Fira Sans", ${base.fonts.body}`,
    heading: `"Poppins", "inherit"`,
    banner: `"Merriweather", ${base.fonts.body}`,
  },
  colors: {
    ...base.colors,
    "gray-100": "#f7fafc",
    "gray-200": "#edf2f7",
    "gray-300": "#e2e8f0",
    "gray-400": "#cbd5e0",
    "gray-500": "#a0aec0",
    "gray-600": "#718096",
    "gray-700": "#4a5568",
    "gray-800": "#2d3748",
    "gray-900": "#1a202c",
    "blue-100": "#ebf8ff",
    "blue-200": "#bee3f8",
    "blue-300": "#90cdf4",
    "blue-400": "#63b3ed",
    "blue-500": "#4299e1",
    "blue-600": "#3182ce",
    "blue-700": "#2b6cb0",
    "blue-800": "#2c5282",
    "blue-900": "#2a4365",
    "red-100": "#fff5f5",
    "red-200": "#fed7d7",
    "red-300": "#feb2b2",
    "red-400": "#fc8181",
    "red-500": "#f56565",
    "red-600": "#e53e3e",
    "red-700": "#c53030",
    "red-800": "#9b2c2c",
    "red-900": "#742a2a",
    text: "#1a202c",
    highlight: "#ebf8ff",
  },
  buttons: {
    primary: {
      cursor: "pointer",
      borderRadius: "small",
      boxShadow: "0px 2px 2px gray",
      bg: "gray-600",
      fontWeight: "bold",
      color: "gray-200",
      "&:hover": {
        bg: "gray-700",
      },
      "&:active": {
        bg: "gray-800",
      },
      transition:
        "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
    blue: {
      cursor: "pointer",
      borderRadius: "small",
      boxShadow: "0px 2px 2px gray",
      bg: "blue-600",
      fontWeight: "bold",
      color: "blue-100",
      "&:hover": {
        bg: "blue-700",
      },
      "&:active": {
        bg: "blue-800",
      },
      transition:
        "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
    disabled: {
      bg: "gray-500",
    },
  },
  forms: {
    input: {
      borderColor: "gray",
      borderRadius: "small",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "gray",
      borderRadius: "small",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    select: {
      borderColor: "gray",
      borderRadius: "small",
      lineHeight: ["heading", "body"],
    },
    label: {
      fontWeight: "bold",
    },
  },
  messages: {
    error: {
      borderLeftColor: "red-700",
      bg: "red-100",
    },
  },
}
